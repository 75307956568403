import React, { FC } from 'react';
import { IB2BOrderDetails } from '../../../../../interfaces/b2bV3OrdersList';
import GetCopyTextDiv from '../../../../CommonComponents/GetCopyTextDiv';
import moment from 'moment';
import { Button, Tag } from 'antd';
import { CLOUDINARY_URL } from '../../../../../utils/config';
import { FaDownload } from 'react-icons/fa';

interface IBasicDetails {
  detailsData: IB2BOrderDetails | undefined;
}
const BasicDetails: FC<IBasicDetails> = ({ detailsData }) => {
  const onClickDownload = (link: string | undefined) => {
    if (link) window.open(link, '_blank');
  };
  return (
    <>
      <h1 className="font-semibold text-xl mb-4">Basic Details</h1>
      <div className="flex gap-2 flex-wrap">
        <div className="flex flex-col gap-2 w-full lg:w-[48%]">
          <div className="flex gap-2">
            <p className="font-semibold w-[150px] flex gap-1 justify-between">
              Order Id <span>:</span>
            </p>
            {detailsData?.id ? <GetCopyTextDiv text={detailsData?.id} /> : 'NA'}
          </div>

          <div className="flex gap-2">
            <p className="font-semibold w-[150px] flex gap-1 justify-between">
              Created At <span>:</span>
            </p>
            <p>
              {detailsData?.createdAt
                ? moment(detailsData?.createdAt).format('YYYY-MM-DD, HH:mm:ss')
                : 'NA'}
            </p>
          </div>

          <div className="flex gap-2">
            <p className="font-semibold w-[150px] flex gap-1 justify-between">
              Insurance Id <span>:</span>
            </p>
            {detailsData?.insuranceId ? <GetCopyTextDiv text={detailsData?.insuranceId} /> : 'NA'}
          </div>

          <div className="flex gap-2 items-start">
            <p className="font-semibold w-[150px] flex gap-1 justify-between">
              Client Order Reference Id <span>:</span>
            </p>
            {detailsData?.clientOrderReferenceId ? (
              <GetCopyTextDiv text={detailsData?.clientOrderReferenceId} />
            ) : (
              'NA'
            )}
          </div>

          <div className="flex gap-2">
            <p className="font-semibold w-[150px] flex gap-1 justify-between">
              Bulk Order Id <span>:</span>
            </p>
            {detailsData?.bulkOrderId ? <GetCopyTextDiv text={detailsData?.bulkOrderId} /> : 'NA'}
          </div>

          <div className="flex gap-2">
            <p className="font-semibold w-[150px] flex gap-1 justify-between">
              Order Type <span>:</span>
            </p>
            <Tag>{detailsData?.orderType || 'NA'}</Tag>
          </div>

          <div className="flex gap-2">
            <p className="font-semibold w-[150px] flex gap-1 justify-between">
              Payment Type <span>:</span>
            </p>

            <div className="flex items-center gap-1">
              <Tag>{detailsData?.paymentType || 'NA'}</Tag>

              {detailsData?.paymentType === 'BNPL' && (
                <img
                  src={`${CLOUDINARY_URL}/ccd-icons/post-paid-payment.png`}
                  alt=""
                  className="w-8 h-8"
                />
              )}

              {detailsData?.paymentType === 'WALLET' && (
                <img
                  src={`${CLOUDINARY_URL}/ccd-icons/wallet-payment.png`}
                  alt=""
                  className="w-8 h-8"
                />
              )}
            </div>
          </div>

          <div className="flex gap-2">
            <div className="flex gap-2">
              <p className="font-semibold w-[150px] flex gap-1 justify-between">
                Source <span>:</span>
              </p>
              <Tag>{detailsData?.source?.split('_')?.join(' ') || 'NA'}</Tag>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-2 w-full lg:w-[48%]">
          <div className="flex gap-2">
            <p className="font-semibold w-[150px] flex gap-1 justify-between">
              Status <span>:</span>
            </p>
            <Tag>{detailsData?.status?.split('_')?.join(' ') || 'NA'}</Tag>
          </div>

          <div className="flex gap-2">
            <p className="font-semibold w-[150px] flex gap-1 justify-between">
              Total Dimension <span>:</span>
            </p>
            <div className="flex flex-wrap gap-1 font-semibold">
              <p>{detailsData?.totalLength} ( L )</p>
              <p>--</p>
              <p>{detailsData?.totalWidth} ( W )</p>
              <p>--</p>
              <p>{detailsData?.totalHeight} ( H )</p>
            </div>
          </div>

          <div className="flex gap-2">
            <p className="font-semibold w-[150px] flex gap-1 justify-between">
              Total Weight <span>:</span>
            </p>
            <p>{detailsData?.totalWeight}</p>
          </div>

          <div className="flex gap-2">
            <p className="font-semibold w-[150px] flex gap-1 justify-between">
              Total Partners <span>:</span>
            </p>
            <p>{detailsData?.totalPartners}</p>
          </div>

          <div className="flex gap-2">
            <p className="font-semibold w-[150px] flex gap-1 justify-between">
              Total Product Value <span>:</span>
            </p>
            <p>{detailsData?.totalProductValue}</p>
          </div>

          <div className="flex gap-2">
            <p className="font-semibold w-[150px] flex gap-1 justify-between">
              Tracking Status <span>:</span>
            </p>
            <Tag>{detailsData?.trackingStatus || 'NA'}</Tag>
          </div>

          {!!detailsData?.documents?.filter((item) => item?.type === 'SHIPPING_LABEL')?.length && (
            <div className="flex gap-2 items-center">
              <p className="font-semibold w-[150px] flex gap-1 justify-between m-0">
                Shipment Label <span>:</span>
              </p>
              <Button
                onClick={() =>
                  onClickDownload(
                    detailsData?.documents?.filter((item) => item?.type === 'SHIPPING_LABEL')[0]
                      ?.url
                  )
                }
                type="primary"
                icon={<FaDownload />}>
                Download
              </Button>
            </div>
          )}

          {!!detailsData?.documents?.filter((item) => item?.type === 'INVOICE')?.length && (
            <div className="flex gap-2 items-center">
              <p className="font-semibold w-[150px] flex gap-1 justify-between m-0">
                Invoice <span>:</span>
              </p>
              <Button
                onClick={() =>
                  onClickDownload(
                    detailsData?.documents?.filter((item) => item?.type === 'INVOICE')[0]?.url
                  )
                }
                type="primary"
                icon={<FaDownload />}>
                Download
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BasicDetails;
