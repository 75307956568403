import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Pagination, Select, Table, Tag, Typography } from 'antd';

import useOrderListDetails from './api';
import { CLOUDINARY_URL } from '../../../utils/config';

// types
import type { PaginationProps } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { GetProviderLogo } from '../../../utils/GetProviderLogos';
import { useNavigate } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import GetStatus from '../../../utils/GetStatus';
import { PROVIDER_NAMES } from '../../../utils/constant';
import YellowCircle from '../../CommonComponents/YellowCircle';
import DateRangeFilter from '../../CommonComponents/TableFilterComponents/DateRangeFilter';
import GetOrderByIdFilter from '../../CommonComponents/TableFilterComponents/GetOrderById';

type ProviderOption = {
  value: string;
  label: string;
};

const Orders: React.FC = () => {
  const { getOrdersList, orderData, isLoading } = useOrderListDetails();

  const navigate = useNavigate();
  const [orderIdSearchForm] = Form.useForm();
  const [awbIdSearchForm] = Form.useForm();
  const [deliverVendorForm] = Form.useForm();
  const [orderStatusForm] = Form.useForm();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);
  const [orderId, setOrderId] = useState('');
  const [daterange, setDaterange] = useState({ from: '', to: '' });
  const [awbId, setAwbId] = useState('');
  const [deliveryVendor, setDeliveryVendor] = useState('');
  const [orderStatus, setOrderStatus] = useState('');

  // order list api
  const handleGetOrder = () => {
    getOrdersList({
      pageNumber: currentPage,
      pageLimit: pageLimit,
      from: daterange?.from,
      to: daterange?.to,
      orderId: orderId,
      awbNo: awbId,
      serviceProvider: deliveryVendor,
      orderStatus: orderStatus
    });
  };

  useEffect(() => {
    handleGetOrder();
  }, [currentPage, pageLimit, daterange, orderId, awbId, deliveryVendor, orderStatus]);

  const onPageChange: PaginationProps['onChange'] = (pageNo, limit) => {
    setCurrentPage(pageNo);
    setPageLimit(limit);
  };

  // Delivery Vendor Functions
  const handleDeliverySearch = ({ deliverVendor }: { deliverVendor: string }) => {
    setDeliveryVendor(deliverVendor);
  };
  const handleDeliveryReset = () => {
    setDeliveryVendor('');
    deliverVendorForm.resetFields();
  };

  const PROVIDER_NAMES_OPTIONS: ProviderOption[] = Object.values(PROVIDER_NAMES).map((value) => ({
    value,
    label: value
  }));

  const getDeliveryVendor = () => ({
    filterDropdown: () => (
      <div className="p-2" onKeyDown={(e) => e.stopPropagation()}>
        <Form
          form={deliverVendorForm}
          layout="vertical"
          id="deliveryVendorForm"
          onFinish={handleDeliverySearch}>
          <Form.Item name="deliverVendor">
            <Select
              showSearch
              allowClear
              placeholder="Select Delivery Vendor"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              style={{ width: 180 }}
              options={PROVIDER_NAMES_OPTIONS}
            />
          </Form.Item>
        </Form>
        <div className="flex gap-3 justify-end">
          <Button onClick={handleDeliveryReset} size="small">
            Reset
          </Button>
          <Button
            htmlType="submit"
            form="deliveryVendorForm"
            size="small"
            className="primary-button">
            Search
          </Button>
        </div>
      </div>
    )
  });

  // Order Status Functions
  const handleStatusSearch = ({ orderStatus }: { orderStatus: string }) => {
    setOrderStatus(orderStatus);
  };
  const handleStatusReset = () => {
    setOrderStatus('');
    orderStatusForm.resetFields();
  };
  const getOrderStatus = () => ({
    filterDropdown: () => (
      <div className="p-2" onKeyDown={(e) => e.stopPropagation()}>
        <Form
          form={orderStatusForm}
          layout="vertical"
          id="orderStatusForm"
          onFinish={handleStatusSearch}>
          <Form.Item name="orderStatus">
            <Select
              showSearch
              placeholder="Select Order Status"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              style={{ width: 180 }}
              options={[
                {
                  value: 'FAILED',
                  label: 'FAILED'
                },
                {
                  value: 'SUCCESS',
                  label: 'SUCCESS'
                },
                { value: 'PROCESSING', label: 'PROCESSING' },
                {
                  value: 'UNKNOWN',
                  label: 'UNKNOWN'
                }
              ]}
            />
          </Form.Item>
        </Form>
        <div className="flex gap-3 justify-end">
          <Button onClick={handleStatusReset} size="small">
            Reset
          </Button>
          <Button htmlType="submit" form="orderStatusForm" size="small" className="primary-button">
            Search
          </Button>
        </div>
      </div>
    )
  });

  // eslint-disable-next-line
  const columns: ColumnsType<any> = [
    {
      dataIndex: 'orderId',
      key: 'orderId',
      fixed: 'left',
      width: 200,
      render: (id) =>
        id ? (
          <div className="flex items-center gap-1 flex-wrap">
            <Link to={`/order-details/${id}`} target="_blank">
              <p className={`m-0 text-blue-500 hover:underline`}>
                <b>{id}</b>
              </p>
            </Link>
            <Typography.Text copyable={{ text: id }} />
          </div>
        ) : (
          <p>NA</p>
        ),
      ...GetOrderByIdFilter({
        orderId: orderId,
        setOrderId: setOrderId,
        formInstance: orderIdSearchForm,
        formId: 'OrderIdForm'
      })
    },
    {
      dataIndex: 'trackingNumber',
      key: 'trackingNumber',
      width: 150,
      render: (orderId) => <div>{orderId ? orderId : 'NA'}</div>,
      ...GetOrderByIdFilter({
        orderId: awbId,
        setOrderId: setAwbId,
        formInstance: awbIdSearchForm,
        title: 'AWB #',
        placeHolder: 'AWB #',
        formId: 'AwbIdForm'
      })
    },
    {
      title: (
        <div className="flex items-center">
          Delivery vendor
          {deliveryVendor ? <YellowCircle /> : null}
        </div>
      ),
      dataIndex: 'deliveryVendor',
      key: 'deliveryVendor',
      render: (text) => (
        <img
          src={GetProviderLogo(text)}
          alt="provider"
          style={window.innerWidth > 699 ? { width: '6rem' } : { width: '3rem' }}
        />
      ),
      width: 150,
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      ...getDeliveryVendor()
    },
    {
      title: 'Pickup customer name',
      dataIndex: 'pickupDetails',
      key: 'pickupDetails',
      render: (pickupDetails) => pickupDetails?.customerName
    },
    {
      title: 'Delivery customer name',
      dataIndex: 'deliveryDetails',
      key: 'deliveryDetails',
      render: (deliveryDetails) => deliveryDetails?.customerName
    },
    {
      title: 'Pickup Address City',
      dataIndex: 'pickupDetails',
      key: 'pickupDetails',
      render: (pickupDetails) => pickupDetails?.city
    },
    {
      title: 'Delivery Address City',
      dataIndex: 'deliveryDetails',
      key: 'deliveryDetails',
      render: (deliveryDetails) => deliveryDetails?.city
    },
    {
      width: 150,
      dataIndex: 'createdAt',
      key: 'createdAt',
      ...DateRangeFilter({ setDaterange: setDaterange, daterange: daterange })
    },
    {
      title: 'Tracking Status',
      width: 200,
      dataIndex: 'trackingStatus',
      className: 'capitalize',
      render: (status) => <Tag className="font-bold text-grey-400 uppercase">{status}</Tag>
    },
    {
      title: (
        <div className="flex items-center">
          Order Status
          {orderStatus ? <YellowCircle /> : null}
        </div>
      ),
      dataIndex: 'orderStatus',
      key: 'orderStatus',
      render: (status, ordersList) => {
        const orderStatusContent = (
          <GetStatus
            currStatus={status}
            PROCESSING="Processing"
            SUCCESS="Order Created"
            FAILED="Order Failed"
            CANCELLED="Cancelled"
          />
        );
        const paymentStatusContent =
          ordersList.paymentVendor === 'COREYOWALLET' ? (
            ordersList.isRefunded === true ? (
              <img
                src={`${CLOUDINARY_URL}/ccd-icons/wallet-payment-refunded.png`}
                alt=""
                className="w-8 h-8"
              />
            ) : (
              <img
                src={`${CLOUDINARY_URL}/ccd-icons/wallet-payment.png`}
                alt=""
                className="w-8 h-8"
              />
            )
          ) : ordersList.paymentVendor === 'RAZORPAY' ? (
            ordersList.isRefunded === true ? (
              <img
                src={`${CLOUDINARY_URL}/ccd-icons/razorpay-payment-refunded.png`}
                alt=""
                className="w-8 h-8"
              />
            ) : (
              <img
                src={`${CLOUDINARY_URL}/ccd-icons/razorpay-payment.png`}
                alt=""
                className="w-8 h-8"
              />
            )
          ) : ordersList.paymentVendor === 'JUSPAY' ? (
            ordersList.isRefunded === true ? (
              <img
                src={`${CLOUDINARY_URL}/ccd-icons/juspay-payment-refunded.png`}
                alt=""
                className="w-8 h-8"
              />
            ) : (
              <img
                src={`${CLOUDINARY_URL}/ccd-icons/juspay-payment.png`}
                alt=""
                className="w-8 h-8"
              />
            )
          ) : ordersList.paymentVendor === 'COREYO' ? (
            ordersList.isRefunded === true ? (
              <img
                src={`${CLOUDINARY_URL}/ccd-icons/post-paid-payment-refunded.png`}
                alt=""
                className="w-8 h-8"
              />
            ) : (
              <img
                src={`${CLOUDINARY_URL}/ccd-icons/post-paid-payment.png`}
                alt=""
                className="w-8 h-8"
              />
            )
          ) : ordersList.isRefunded === true ? (
            <img
              src={`${CLOUDINARY_URL}/ccd-icons/post-paid-payment-refunded.png`}
              alt=""
              className="w-8 h-8"
            />
          ) : (
            <img
              src={`${CLOUDINARY_URL}/ccd-icons/post-paid-payment.png`}
              alt=""
              className="w-8 h-8"
            />
          );

        return (
          <div className="flex flex-column gap-2 items-center">
            {paymentStatusContent}
            {orderStatusContent}
          </div>
        );
      },
      width: 180,
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      ...getOrderStatus()
    }
    // {
    //   title: 'Action',
    //   fixed: 'right',
    //   render: (text, record) => (
    //     <Button
    //       className="primary-button"
    //       onClick={() => navigate(`/order-details/${record.orderId}`)}>
    //       View Details
    //     </Button>
    //   )
    // }
  ];

  return (
    <div>
      <div className="mb-4 flex items-center justify-between">
        <h2 className="text-xl font-normal m-0">Orders List</h2>
        <Button className="m-0" onClick={handleGetOrder}>
          Refresh
        </Button>
      </div>
      <Table
        columns={columns}
        loading={isLoading}
        rowKey={'orderId'}
        scroll={{ x: 1600 }}
        pagination={false}
        dataSource={orderData?.ordersList}
      />
      <br />
      <div className="flex justify-end">
        <Pagination
          responsive={true}
          showQuickJumper
          current={currentPage}
          pageSize={pageLimit}
          pageSizeOptions={[20, 30, 50]}
          total={orderData?.totalPage ? orderData?.totalPage * pageLimit : 0}
          onChange={onPageChange}
        />
      </div>
    </div>
  );
};
export default Orders;
