import { Dispatch, SetStateAction } from 'react';
import { ErrorMessage, showMessage } from '../../../utils/ResponseMessages';
import { httpClient } from '../../../utils/apiRequest';
import { IECBOEmailVerifiedData } from '../../../interfaces/ecboBulkOrder';

export const verifyUserEmail = async ({
  setLoading,
  setVerified,
  setUserData,
  email
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setVerified: Dispatch<SetStateAction<boolean>>;
  setUserData: Dispatch<SetStateAction<IECBOEmailVerifiedData | undefined>>;
  email: string;
}) => {
  setLoading(true);

  try {
    const response = await httpClient?.get(`/ecbo/fetch-user/${email}`);

    if (response?.data?.status >= 200 && response?.data?.status <= 300) {
      setVerified(true);
      setUserData(response?.data?.data);
    } else {
      setVerified(false);
      setUserData(undefined);
      ErrorMessage(response?.data?.message);
    }
  } catch (error: any) {
    setVerified(false);
    ErrorMessage(error?.response?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const createBulkOrder = async (payload: { [key: string]: any }) => {
  try {
    const response = await httpClient?.post('/ecbo/book', payload);
    return response;
  } catch (error) {
    return error;
  }
};

export const generateBulkOrderId = async ({
  setLoading
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
}): Promise<any> => {
  setLoading(true);
  try {
    const response = await httpClient?.get('/ecbo/batch-id');
    setLoading(false);
    return response?.data;
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
    setLoading(false);
  }
};

export const generateDockets = async ({
  setLoading,
  setData,
  quantity,
  userId
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<SetStateAction<string[]>>;
  quantity: string;
  userId?: string | undefined;
}) => {
  setLoading(true);
  const payload = {
    userId: userId,
    quantity: Number(quantity)
  };

  try {
    const response = await httpClient?.post('/ecbo/docket', payload);
    setLoading(false);
    setData(response?.data?.data?.dockets);
  } catch (error: any) {
    setData([]);
    showMessage(error?.response?.data?.message, error?.response?.status);
    setLoading(false);
  }
};

export const generateInvoice = async ({
  bulkId,
  setLoading,
  setData
}: {
  bulkId: string;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<
    SetStateAction<{
      invoice?: string;
      shipmentLabel?: string;
      invoiceUrl?: string;
      shipmentLabelUrl?: string;
    }>
  >;
}): Promise<any> => {
  setLoading(true);
  try {
    const response = await httpClient?.get(`/ecbo/invoice-label/${bulkId}`);
    setData({
      invoice: response?.data?.data?.invoice,
      shipmentLabel: response?.data?.data?.shipmentLabel,
      invoiceUrl: response?.data?.data?.invoiceUrl,
      shipmentLabelUrl: response?.data?.data?.shipmentLabelUrl
    });
    setLoading(false);
  } catch (error: any) {
    setLoading(false);
    showMessage(error?.response?.data?.message, error?.response?.status);
  }
};
