import { Button, Space, Tag } from "antd";
import React, { useState } from "react";
import TextArea from "antd/es/input/TextArea";
import { CloseOutlined } from "@ant-design/icons"; // Import custom close icon
import { multiTracking } from "../api";

const Tracking: React.FC = () => {
  const [tags, setTags] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>("");

  // Handle paste event
  const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    e.preventDefault();

    const pastedText = (e.clipboardData || window.Clipboard).getData("text");

    const cells = pastedText
      .split(/\t|,|\n/)
      .map((cell) => cell.trim())
      .filter((cell) => cell);

    // Append new tags to the existing ones
    setTags((prevTags) => [...prevTags, ...cells]);
  };

  // Handle input change
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };

  // Handle key press (e.g., Enter key)
  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && inputValue.trim()) {
      e.preventDefault();

      // Add the entered value as a new tag
      setTags((prevTags) => [...prevTags, inputValue.trim()]);
      setInputValue(""); // Clear the input field
    }
  };

  // Handle tag removal
  const handleRemoveTag = (removedTag: string) => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== removedTag));
  };

  const handleClear = () => {
    setTags([]); // Clear tags
  };

  const handleSubmit = async () => {
    console.log("Submitted Tags:", tags);
    const data = await multiTracking(tags);
    window.open(data.data.url);
    console.log(data);
  };

  return (
    <div>
      <h2 className="text-xl font-normal m-0 mb-4">Tracking</h2>
      <div className="flex flex-col items-center gap-5">
        <div style={{ padding: "20px", width: "100%" }}>
          <h2 className="font-bold">OrderIds/Tracking Number :</h2>
          <div
            style={{
              marginBottom: "20px",
              minHeight: "100px",
              padding: "10px",
              border: "1px solid #d9d9d9",
              borderRadius: "5px",
              backgroundColor: "#f5f5f5",
            }}
          >
            <Space wrap>
              {tags.map((tag, index) => (
                <Tag
                  color="blue"
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 10px",
                    borderRadius: "20px",
                  }}
                >
                  <span style={{ marginRight: "8px" }}>{tag}</span>
                  <CloseOutlined
                    style={{ fontSize: "12px", cursor: "pointer" }}
                    onClick={() => handleRemoveTag(tag)}
                  />
                </Tag>
              ))}
            </Space>
          </div>
          <TextArea
            rows={6}
            placeholder="Paste your Excel data here..."
            onPaste={handlePaste}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            value={inputValue}
          />
          <Space style={{ marginTop: "20px" }}>
            <Button type="primary" onClick={handleSubmit}>
              Submit
            </Button>
            <Button type="default" onClick={handleClear}>
              Clear
            </Button>
          </Space>
        </div>
      </div>
    </div>
  );
};

export default Tracking;
