import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Form, FormInstance, Spin, Table } from 'antd';
import { BulkOrderColumns } from './Partials/BulkOrderColumns';
import { checkValidation } from './Partials/BulkOrderUtils';
import { createBulkOrder, generateBulkOrderId, getHsnCategories } from '../../api';

interface BulkOrdersTableProps {
  ordersFile: { [key: string]: any }[];
  setOrdersFile: Dispatch<
    SetStateAction<
      {
        [key: string]: any;
      }[]
    >
  >;
  ordersValidated: boolean;
  setOrdersValidated: Dispatch<SetStateAction<boolean>>;
  setErrorMsg: Dispatch<SetStateAction<string>>;
  ordersCount: number;
  setOrdersCount: Dispatch<SetStateAction<number>>;
  maxOrdersAllowed: number;
  ordersResponseData: {
    [key: string]: any;
  }[];
  setOrdersResponseData: Dispatch<
    SetStateAction<
      {
        [key: string]: any;
      }[]
    >
  >;
  tableLoading: boolean;
  setTableLoading: Dispatch<SetStateAction<boolean>>;
  setBulkId: Dispatch<SetStateAction<string>>;
  bulkLoading: boolean;
  setBulkLoading: Dispatch<SetStateAction<boolean>>;
  failedOrderLoading: boolean;
  customPriceAdded: boolean;
  setCustomPriceAdded: Dispatch<SetStateAction<boolean>>;
  // eslint-disable-next-line
  customBulkOrderForm: FormInstance<any>;
  commCategory: string;
  setCommCategory: Dispatch<SetStateAction<string>>;
}

const BulkOrdersTable: React.FC<BulkOrdersTableProps> = ({
  ordersFile,
  setOrdersFile,
  setOrdersValidated,
  setErrorMsg,
  ordersCount,
  setOrdersCount,
  maxOrdersAllowed,
  ordersResponseData,
  setOrdersResponseData,
  tableLoading,
  setTableLoading,
  setBulkId,
  bulkLoading,
  setBulkLoading,
  failedOrderLoading,
  customPriceAdded,
  setCustomPriceAdded,
  customBulkOrderForm,
  commCategory,
  setCommCategory
}) => {
  const [isCustomPrice, setIsCustomPrice] = useState<boolean>(true);
  const [customPrice, setCustomPrice] = useState<string>('-');
  const [hsnCategoriesList, setHsnCatgoriesList] = useState<{ [key: string]: any }[]>([]);
  const [categoriesType, setCategoriesType] = useState<string>('individual');
  const [processedOrdersCount, setProcessedOrdersCount] = useState<number>(0);

  useEffect(() => {
    // code to show a error message if there is any invalid orders
    const invalidOrders = ordersFile?.map((order) => checkValidation(order));
    if (invalidOrders?.filter((item) => item?.invalidOrder?.length)?.length > 0) {
      setErrorMsg('Please check the errors in the table.');
      setOrdersValidated(false);
    } else {
      setOrdersValidated(true);
      setErrorMsg('');
    }
    if (!ordersFile?.length) {
      setErrorMsg('');
    }

    if (ordersFile?.length && !hsnCategoriesList?.length) {
      getHsnCategories().then((res) => {
        setHsnCatgoriesList(res?.data?.data);
      });
    }

    if (
      customPriceAdded === false &&
      ordersFile?.filter((item) => item?.custom_price)?.length > 0
    ) {
      setCustomPriceAdded(true);
    }
  }, [ordersFile]);

  useEffect(() => {
    // code to append the response message to orders file
    if (ordersResponseData?.length === ordersFile?.length) {
      const updatedData = ordersResponseData?.map((order) => ({
        ...order,
        id: order?.key
      }));
      setOrdersFile(updatedData);
      setOrdersCount(0);
    }
  }, [ordersResponseData]);

  const onItemCategoryChange = (id: string, value: string) => {
    if (categoriesType === 'individual') {
      const updatedData = ordersFile?.map((order) =>
        order?.id === id
          ? {
              ...order,
              item_category: value
            }
          : order
      );
      setOrdersFile(updatedData);
      setCommCategory('');
    } else {
      setCommCategory(value);
    }
  };

  const onUploadOrders = async () => {
    setProcessedOrdersCount(0);
    const response = await generateBulkOrderId({ setLoading: setBulkLoading });

    if (response?.status >= 200 && response?.status <= 300) {
      setBulkId(response?.data);
      const payload = ordersFile?.map((order) => {
        order = {
          ...order,
          bulkOrderId: response?.data,
          key: order?.id,
          shipment_length: Number(order?.shipment_length),
          shipment_height: Number(order?.shipment_height),
          shipment_width: Number(order?.shipment_width),
          billing_address_pincode: order?.billing_address_pincode?.toString(),
          billing_phone_number: order?.billing_phone_number?.toString(),
          delivery_address_pincode: order?.delivery_address_pincode?.toString(),
          delivery_phone_number: order?.delivery_phone_number?.toString(),
          pickup_address_pincode: order?.pickup_address_pincode?.toString(),
          pickup_phone_number: order?.pickup_phone_number?.toString(),
          weight: order?.weight?.toString(),
          estimated_value: order?.estimated_value?.toString(),
          custom_price: Number(order?.custom_price),
          ewayBill: order?.ewayBill || null,
          expiryDate: order?.expiryDate || null,
          sellerGstNo: order?.sellerGstNo || null,
          buyerGstNo: order?.buyerGstNo || null,
          productInvoiceNo: order?.productInvoiceNo || null,
          productInvoiceDate: order?.productInvoiceDate || null,
          taxableValue: order?.taxableValue || null,
          cgstAmount: order?.cgstAmount || null,
          sgstAmount: order?.sgstAmount || null,
          igstAmount: order?.igstAmount || null
        };
        if (categoriesType !== 'individual') {
          order = {
            ...order,
            item_category: commCategory
          };
        }
        if (isCustomPrice && !ordersFile?.filter((item) => item?.custom_price)?.length) {
          order = {
            ...order,
            custom_price: Number(customPrice)
          };
        }
        return order;
      });
      setOrdersResponseData([]);
      setTableLoading(true);
      payload?.map(async (order, index) => {
        delete order?.id;
        setTimeout(async () => {
          const { data }: any = await createBulkOrder(order);
          setProcessedOrdersCount((prev) => prev + 1);
          setOrdersResponseData((prev) => [...prev, { ...data?.message?.data, ...order }]);
          if (payload?.length === index + 1) {
            setTableLoading(false);
          }
        }, 5000 * index);
      });
    }
  };

  const handleEditCustomPrice = (orderId: string, newValue: string) => {
    const updatedData = ordersFile?.map((item) =>
      item?.id === orderId ? { ...item, custom_price: Number(newValue) } : item
    );
    setOrdersFile(updatedData);
  };

  return (
    <div className="p-2">
      {ordersCount && ordersCount > maxOrdersAllowed ? (
        <p className="mb-1 text-red-600">*Showing first {maxOrdersAllowed} orders only.</p>
      ) : null}
      {ordersFile?.length ? (
        <Form
          form={customBulkOrderForm}
          scrollToFirstError
          id={'orderForm'}
          layout="vertical"
          onFinish={onUploadOrders}>
          <Table
            columns={BulkOrderColumns({
              isCustomPrice: isCustomPrice,
              customPrice: customPrice,
              setCustomPrice: setCustomPrice,
              hsnCategoriesList: hsnCategoriesList,
              categoriesType: categoriesType,
              setCategoriesType: setCategoriesType,
              onItemCategoryChange: onItemCategoryChange,
              commCategory: commCategory,
              bookedAllItems: ordersResponseData?.length === ordersFile?.length,
              isPriceAdded: customPriceAdded,
              handleEditCustomPrice: handleEditCustomPrice
            })}
            loading={{
              spinning: tableLoading || bulkLoading || failedOrderLoading,
              indicator: (
                <div
                  style={{
                    width: 'fit-content',
                    transform: 'translateX(-50%)'
                  }}>
                  <Spin />
                  {!!failedOrderLoading && (
                    <p className="mb-0">Please wait, pushing failed orders... </p>
                  )}
                  {!!bulkLoading && <p className="mb-0">Getting orders ready...</p>}
                  {!!tableLoading && (
                    <p className="mb-0 font-bold">
                      Please wait, booking orders... <br />
                      Processed {processedOrdersCount} / {ordersFile?.length} orders
                    </p>
                  )}
                </div>
              )
            }}
            dataSource={ordersFile}
            bordered
            rowKey={'id'}
            scroll={{
              x: 5000
            }}
            pagination={false}
            sticky
            expandable={{
              defaultExpandAllRows: true,
              showExpandColumn: false,
              expandedRowRender: (record) => {
                const { invalidOrder } = checkValidation(record);
                return (
                  <ul>
                    {invalidOrder?.map((err, index) => (
                      <li key={index} className="text-red-600">
                        {err}
                      </li>
                    ))}
                  </ul>
                );
              },
              rowExpandable: (record) =>
                checkValidation(record)?.invalidOrder?.length ? true : false
            }}
          />
        </Form>
      ) : null}
    </div>
  );
};

export default BulkOrdersTable;
