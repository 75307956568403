import React, { FC, useState } from 'react';
import {
  IB2BOrderCarrier,
  IB2BOrderDetails,
  IB2BOrderItem,
  IB2BOrderPackage
} from '../../../../../interfaces/b2bV3OrdersList';
import { ColumnsType } from 'antd/es/table';
import { Button, Table, Tag } from 'antd';
import { GetProviderLogo } from '../../../../../utils/GetProviderLogos';
import moment from 'moment';
import GetCopyTextDiv from '../../../../CommonComponents/GetCopyTextDiv';
import ItemTableModal from '../ItemTableModal';

interface ICarrierDetails {
  detailsData: IB2BOrderDetails | undefined;
}
const CarrierDetails: FC<ICarrierDetails> = ({ detailsData }) => {
  const [selectedItems, setSelectedItems] = useState<IB2BOrderItem[]>([]);
  const [isItemsModalOpen, setIsItemsModalOpen] = useState<boolean>(false);

  const carrierDetailsColumns: ColumnsType<IB2BOrderCarrier> = [
    {
      title: 'Carrier',
      dataIndex: 'carrier',
      key: 'carrier',
      width: 100,
      render: (text, item) => (
        <img
          src={item?.logo ? item?.logo : GetProviderLogo(text || '')}
          alt="provider"
          style={window.innerWidth > 699 ? { width: '6rem' } : { width: '3rem' }}
        />
      )
    },
    {
      title: 'Service',
      dataIndex: 'service',
      key: 'service',
      width: 100,
      render: (text) => <Tag>{text || '--'}</Tag>
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,
      render: (text: string) => <span>{moment(text).format('YYYY-MM-DD, HH:mm:ss')}</span>
    },
    {
      title: 'Total Packages',
      dataIndex: 'packages',
      key: 'packages',
      width: 200,
      render: (text) => text?.length || '0'
    },
    {
      title: 'Tracking Number',
      dataIndex: 'trackingNumber',
      key: 'trackingNumber',
      width: 200,
      render: (text) => text || '--'
    }
  ];

  const handleItemsModal = (data: IB2BOrderItem[]) => {
    setSelectedItems(data);
    setIsItemsModalOpen(true);
  };
  const packagesColumns: ColumnsType<IB2BOrderPackage> = [
    {
      title: 'Package Id',
      dataIndex: 'id',
      width: 200,
      fixed: 'left',
      render: (id) => <GetCopyTextDiv text={id} />
    },
    {
      title: (
        <div>
          Client Package <br /> Reference Id
        </div>
      ),
      dataIndex: 'clientPackageReferenceId',
      width: 200,
      render: (id) => <GetCopyTextDiv text={id} />
    },
    {
      title: <div>Package Tracking Number</div>,
      dataIndex: 'packageTrackingNumber',
      width: 200,
      render: (id) => <GetCopyTextDiv text={id} />
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      width: 200,
      render: (data) => data || 'NA'
    },
    {
      title: 'Length',
      dataIndex: 'length',
      width: 200,
      render: (data) => data || 'NA'
    },
    {
      title: 'Width',
      dataIndex: 'width',
      width: 200,
      render: (data) => data || 'NA'
    },
    {
      title: 'Height',
      dataIndex: 'height',
      width: 200,
      render: (data) => data || 'NA'
    },
    {
      title: 'Total Items',
      dataIndex: 'totalItems',
      width: 200,
      render: (data) => data || 'NA'
    },
    {
      title: 'Items',
      dataIndex: 'items',
      width: 200,
      render: (data) => <Button onClick={() => handleItemsModal(data)}>View</Button>
    }
  ];

  return (
    <div>
      <h1 className="font-semibold text-xl mb-4">Carriers</h1>

      <Table
        columns={carrierDetailsColumns}
        dataSource={detailsData?.carriers || []}
        pagination={false}
        rowKey={'savedAs'}
        scroll={{ x: 900 }}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <div className="bg-white">
                <p className="my-2 text-xl">
                  <strong>Packages</strong> {`( ${record?.packages?.length} )`}
                </p>
                <Table
                  columns={packagesColumns}
                  dataSource={record?.packages || []}
                  pagination={false}
                />
              </div>
            );
          },
          rowExpandable: (record) => record.packages && record.packages.length > 0
        }}
      />

      <ItemTableModal
        isItemsModalOpen={isItemsModalOpen}
        setIsItemsModalOpen={setIsItemsModalOpen}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
      />
    </div>
  );
};

export default CarrierDetails;
