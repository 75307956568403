import { Dispatch, SetStateAction } from 'react';
import { httpClient } from '../../../../../utils/apiRequest';
import { ErrorMessage, SuccessMessage } from '../../../../../utils/ResponseMessages';
import { IAddressFormFields, ICountry, IStateList } from '../../../../../interfaces/b2bAddressBook';

export const getCountriesList = async ({
  setData
}: {
  setData: Dispatch<SetStateAction<ICountry[] | []>>;
}) => {
  try {
    const response = await httpClient.get(`/businesses/places/countries`);
    setData(response?.data?.data?.countries);
  } catch (error: any) {
    setData([]);
    ErrorMessage(error?.response?.data?.message);
    console.error('Error fetching Country Details', error);
  }
};

export const getStatesList = async ({
  setData,
  countryCode,
  setLoading
}: {
  setData: Dispatch<SetStateAction<IStateList[] | []>>;
  countryCode: string;
  setLoading: Dispatch<SetStateAction<boolean>>;
}) => {
  setLoading(true);

  try {
    const response = await httpClient.get(`/businesses/places/countries/${countryCode}/states`);
    setData(response?.data?.data?.states);
  } catch (error: any) {
    setData([]);
    ErrorMessage(error?.response?.data?.message);
    console.error('Error fetching State Details', error);
  } finally {
    setLoading(false);
  }
};

export const createNewAddress = async ({
  setLoading,
  businessId,
  payload,
  callBackFun
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  businessId: string;
  payload: IAddressFormFields;
  callBackFun?: () => void;
}) => {
  setLoading(true);
  try {
    await httpClient.post(`/businesses/${businessId}/addresses`, payload);
    SuccessMessage('Created Successfully');
    if (callBackFun) callBackFun();
  } catch (error: any) {
    ErrorMessage(error?.response?.data?.message);
    console.error('Error fetching creating new address', error);
  } finally {
    setLoading(false);
  }
};
