import { Button } from 'antd';
import React, { FC, useState } from 'react';
import { MdOpenInNew } from 'react-icons/md';
import { generateBarcode } from '../api';

interface IGenerateReceiptButton {
  id: string;
  userId: string;
}
const GenerateBarcodeButton: FC<IGenerateReceiptButton> = ({ id, userId }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const onGenerateClick = async () => {
    const payload = {
      id,
      userId
    };

    await generateBarcode({
      setLoading: setIsLoading,
      payload: payload
    });
  };
  return (
    <>
      <Button onClick={onGenerateClick} loading={isLoading} icon={<MdOpenInNew />}>
        Generate
      </Button>
    </>
  );
};

export default GenerateBarcodeButton;
