import { Button, Divider, Form, Input } from 'antd';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { IS_PUSH_FAILED_ORDER_ENABLED, MAX_ORDERS_ALLOWED } from '../../../utils/config';
import BulkOrdersTable from './Partials/BulkOrdersTable';
import ExportBulkOrder from './Partials/ExportBulkOrder';
import { ExcelToJson } from '../../../utils/Excel-Conversion/ExcelToJson';
import InvoiceShipmentButton from './Partials/InvoiceShipmentButton';
import useOrderListDetails from '../Orders/api';
import EmailVerificationForm from './Partials/EmailVerificationForm';
import { IEmailVerifiedData } from '../../../interfaces/bulkOrders';

const CustomBulkOrders: React.FC = () => {
  const { pushFailedOrderApi, isLoading } = useOrderListDetails();

  const [customBulkOrderForm] = Form.useForm();
  const [bulkFileUploadForm] = Form.useForm();
  const [ordersValidated, setOrdersValidated] = useState<boolean>(true);
  const [ordersFile, setOrdersFile] = useState<
    {
      [key: string]: any;
    }[]
  >([]);
  const [reOrders, setReOrders] = useState<{
    allOrders?: {
      [key: string]: string | boolean;
    }[];
    successOrders: string[];
    failedOrders: string[];
  }>({ allOrders: [], successOrders: [], failedOrders: [] });

  const [errorMsg, setErrorMsg] = useState<string>('');
  const [ordersCount, setOrdersCount] = useState<number>(0);
  const [ordersResponseData, setOrdersResponseData] = useState<
    {
      [key: string]: any;
    }[]
  >([]);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [bulkId, setBulkId] = useState<string>('');
  const [bulkLoading, setBulkLoading] = useState<boolean>(false);

  const [invoiceLoading, setInvoiceLoading] = useState<boolean>(false);
  const [customPriceAdded, setCustomPriceAdded] = useState<boolean>(false);
  const [commCategory, setCommCategory] = useState<string>('');
  const [fileName, setFileName] = useState<string>('No File Chosen');
  const [loading, setLoading] = useState<boolean>(false);

  const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
  const [emailVerifiedData, setEmailVerifiedData] = useState<IEmailVerifiedData>();

  const maxOrdersAllowed = Number(MAX_ORDERS_ALLOWED); // bulk booking orders limit

  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    setErrorMsg('');
    onClickFile();
    if (event.target.files?.length) {
      setFileName(event.target.files[0]?.name);
      const updatedRes = await ExcelToJson({ dataFile: event?.target?.files[0] });
      const result = updatedRes?.map((order) =>
        emailVerifiedData
          ? {
              ...order,
              billing_email: emailVerifiedData?.email,
              user_id: emailVerifiedData?.user_id
            }
          : order
      );
      setOrdersCount(result?.length);
      setOrdersFile(result?.slice(0, maxOrdersAllowed));
      bulkFileUploadForm.resetFields();
    } else {
      setOrdersFile([]);
    }
    setLoading(false);
  };

  const onClickFile = () => {
    customBulkOrderForm.resetFields();
    setOrdersResponseData([]);
    setOrdersFile([]);
    setOrdersCount(0);
    setCustomPriceAdded(false);
    setBulkId('');
    setCommCategory('');
    setFileName('No File Chosen');
  };

  const handleFailedOrders = () => {
    pushFailedOrderApi({
      orderIDs: bulkId,
      isBulkOrderId: true,
      setResponseData: setReOrders
    });
  };

  useEffect(() => {
    if (reOrders?.allOrders?.length) {
      const updatedOrders = ordersFile.map((item) => {
        const matchingIdsOrder = reOrders?.allOrders?.find(
          (order) => order?.orderId === item?.orderId
        );
        if (matchingIdsOrder && matchingIdsOrder?.awb) {
          return {
            ...item,
            success: true,
            awb: matchingIdsOrder.awb
          };
        } else {
          return {
            ...item,
            awb: item?.awb ? item?.awb : 'NA'
          };
        }
      });
      setOrdersFile(updatedOrders);
    }
  }, [reOrders]);

  return (
    <div>
      <h2 className="text-xl font-normal m-0 mb-4">Book Custom Bulk</h2>

      <div className="bg-white opacity-70 primary-box-shadow rounded-xl">
        <div className="p-5 flex flex-col items-center">
          <EmailVerificationForm
            isValidEmail={isValidEmail}
            setIsValidEmail={setIsValidEmail}
            setEmailVerifiedData={setEmailVerifiedData}
          />

          {isValidEmail && (
            <>
              <Divider />
              <Form form={bulkFileUploadForm}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Please upload a File!'
                    }
                  ]}
                  label="Upload CSV/Excel (XLXS) File"
                  name={'bulkOrderFile'}>
                  <div className="flex items-center gap-2">
                    <Input
                      title={''}
                      className="w-[99px] pl-1"
                      type="file"
                      id="files"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={onFileChange}
                      onClick={onClickFile}
                      suffix={false}
                    />
                    <div title={fileName}>
                      {fileName?.length > 20 ? fileName?.slice(0, 20) + '...' : fileName}
                    </div>
                  </div>
                </Form.Item>
              </Form>

              {loading && <FileProcessingLoader />}

              {ordersValidated && ordersFile?.length ? (
                <Button
                  disabled={ordersResponseData?.length ? true : false}
                  htmlType={'submit'}
                  form={'orderForm'}
                  loading={tableLoading || bulkLoading}>
                  Book Orders
                </Button>
              ) : null}
              <p className="my-2">*Maximum {maxOrdersAllowed} orders allowed.</p>
              <p className="mb-1 text-red-600">{errorMsg}</p>
            </>
          )}
        </div>

        {ordersResponseData?.length && ordersResponseData?.length === ordersFile?.length ? (
          <div className="mb-3 px-3 flex flex-col gap-3 md:flex-row md:items-center md:justify-between">
            <ExportBulkOrder processedOrders={ordersFile} />
            <InvoiceShipmentButton
              updateInvoice={reOrders}
              bulkId={bulkId}
              invoiceLoading={invoiceLoading}
              setInvoiceLoading={setInvoiceLoading}
            />
          </div>
        ) : null}

        {bulkId && ordersResponseData?.length === ordersFile?.length ? (
          <div className="p-3 flex flex-wrap items-center gap-3">
            <h1 className="text-xl">
              Bulk Order Id:- <span className="font-bold">{bulkId}</span>
            </h1>
            {IS_PUSH_FAILED_ORDER_ENABLED ? (
              ordersResponseData?.length &&
              ordersFile?.filter((order) => !order?.success)?.length > 0 ? (
                <Button
                  loading={bulkLoading}
                  className="primary-button"
                  onClick={handleFailedOrders}>
                  Push Failed Orders ( {ordersFile?.filter((order) => !order?.success)?.length} )
                </Button>
              ) : null
            ) : null}
          </div>
        ) : null}

        <BulkOrdersTable
          ordersFile={ordersFile}
          setOrdersFile={setOrdersFile}
          ordersValidated={ordersValidated}
          setOrdersValidated={setOrdersValidated}
          setErrorMsg={setErrorMsg}
          ordersCount={ordersCount}
          setOrdersCount={setOrdersCount}
          maxOrdersAllowed={maxOrdersAllowed}
          ordersResponseData={ordersResponseData}
          setOrdersResponseData={setOrdersResponseData}
          tableLoading={tableLoading}
          setTableLoading={setTableLoading}
          setBulkId={setBulkId}
          bulkLoading={bulkLoading}
          setBulkLoading={setBulkLoading}
          failedOrderLoading={isLoading}
          customPriceAdded={customPriceAdded}
          setCustomPriceAdded={setCustomPriceAdded}
          customBulkOrderForm={customBulkOrderForm}
          commCategory={commCategory}
          setCommCategory={setCommCategory}
        />
      </div>
    </div>
  );
};

const FileProcessingLoader = () => {
  return (
    <button
      type="button"
      className="bg-[#003366] flex h-8 rounded items-center p-3 text-white"
      disabled>
      <svg
        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24">
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      Processing file...
    </button>
  );
};

export default CustomBulkOrders;
