export const formRegex = {
  userEmail: {
    required: 'Please enter email',
    regexPattern: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
    regexMessage: 'Please enter valid email'
  },
  franchiseeId: {
    required: 'Please enter franchisee id.'
  },
  franchiseeName: {
    required: 'Please enter franchisee name'
  },
  address: {
    required: 'Please enter address.',
    regexPattern: /^[a-zA-Z0-9\d\-_@/.,\s]+$/,
    regexMessage: 'Only , . / - _ special characters allowed',
    maxCharactersLength: 50,
    maxCharactersMessage: 'Maximum 50 characters allowed.'
  },
  city: {
    required: 'Please enter city'
  },
  state: {
    required: 'Please enter state'
  },
  country: {
    required: 'Please enter country'
  },
  postalCode: {
    required: 'Please enter postal code',
    regexPattern: /^\d{1,6}$/,
    regexMessage: 'Please enter valid postal code.'
  }
};
