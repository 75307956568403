import React, { useEffect, useState } from 'react';
import { getB2BOrderDetails } from '../api';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button, Divider, Spin } from 'antd';
import { FaBackward } from 'react-icons/fa';
import { IB2BOrderDetails } from '../../../../interfaces/b2bV3OrdersList';
import BasicInformation from './BasicInformation';
import AddressDetails from './AddressDetails';
import CarrierDetails from './CarrierDetails';
import BasicDetails from './BasicDetails';
import UserDetails from './UserDetails';

const B2BOrderDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [detailsData, setDetailsData] = useState<IB2BOrderDetails>();

  const idNotFound = () => {
    navigate('/b2b-orders-list');
  };

  const handleGetDetails = async () => {
    if (id) {
      await getB2BOrderDetails({ id, setLoading, setData: setDetailsData });
    } else idNotFound();
  };

  useEffect(() => {
    handleGetDetails();
  }, [id]);

  return (
    <Spin spinning={loading}>
      <Button
        className="m-0 p-0 flex items-center gap-1"
        type="link"
        onClick={() => window.close()}>
        <FaBackward />
        <span className="hover:underline pl-1">Back</span>
      </Button>

      <div className="flex gap-2 flex-wrap justify-between items-center">
        <h2 className="text-xl font-normal m-0 my-3 flex flex-wrap gap-2">
          <b>Order Details :</b>
          <span>{detailsData?.id ? `( Order ID: ${detailsData.id} )` : ''}</span>
        </h2>

        <Button onClick={handleGetDetails}>Refresh</Button>
      </div>

      <div className="mt-4 flex flex-col gap-4 border border-gray-300 p-4 rounded-md">
        <BasicInformation detailsData={detailsData} />

        <Divider />

        <BasicDetails detailsData={detailsData} />

        <Divider />

        <AddressDetails detailsData={detailsData} />

        <Divider />

        <CarrierDetails detailsData={detailsData} />

        <Divider />

        <UserDetails detailsData={detailsData} />
      </div>
    </Spin>
  );
};

export default B2BOrderDetails;
