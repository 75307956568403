import { Dispatch, SetStateAction } from 'react';
import { ICountry, IStateList } from '../interfaces/addressApis';
import { httpClient } from './apiRequest';
import { ErrorMessage } from './ResponseMessages';

export const getCountriesList = async ({
  setData
}: {
  setData: Dispatch<SetStateAction<ICountry[] | []>>;
}) => {
  try {
    const response = await httpClient.get(`/businesses/places/countries`);
    setData(response?.data?.data?.countries);
  } catch (error: any) {
    setData([]);
    ErrorMessage(error?.response?.data?.message);
    console.error('Error fetching Country Details', error);
  }
};

export const getStatesList = async ({
  setData,
  countryCode,
  setLoading
}: {
  setData: Dispatch<SetStateAction<IStateList[] | []>>;
  countryCode: string;
  setLoading: Dispatch<SetStateAction<boolean>>;
}) => {
  setLoading(true);

  try {
    const response = await httpClient.get(`/businesses/places/countries/${countryCode}/states`);
    setData(response?.data?.data?.states);
  } catch (error: any) {
    setData([]);
    ErrorMessage(error?.response?.data?.message);
    console.error('Error fetching State Details', error);
  } finally {
    setLoading(false);
  }
};
