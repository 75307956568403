import { Button, Empty, Form, Input, Radio, RadioChangeEvent, Select, Spin } from 'antd';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import Formlabel from '../../../../CommonComponents/FormLabel';
import { getBusinessList } from '../../api';
import { IB2BBulkOrder } from '../../../../../interfaces/b2bBulkOrder';

interface IBusinessDetails {
  setBusinessId: Dispatch<SetStateAction<string>>;
  setPageLimit: Dispatch<SetStateAction<number>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}
const BusinessDetails: FC<IBusinessDetails> = ({ setBusinessId, setPageLimit, setCurrentPage }) => {
  const defaultSearchType = 'brandName';
  const [searchBusinessForm] = Form.useForm();
  const [businessListForm] = Form.useForm();

  const clearListForm = () => {
    businessListForm.resetFields();
    setBusinessId('');
    setPageLimit(20);
    setCurrentPage(1);
  };

  const initialState = {
    selectedSearchType: defaultSearchType,
    enteredValue: '',
    searchLoading: false,
    businessList: [] as IB2BBulkOrder[]
  };

  const [selectedSearchType, setSelectedSearchType] = useState(initialState.selectedSearchType);
  const [enteredValue, setEnteredValue] = useState(initialState.enteredValue);
  const [searchLoading, setSearchLoading] = useState(initialState.searchLoading);
  const [businessList, setBusinessList] = useState(initialState.businessList);

  const onChangeSearchType = (e: RadioChangeEvent) => {
    setSelectedSearchType(e.target.value);
    searchBusinessForm.resetFields(['searchInput']);
    clearListForm();
    setEnteredValue('');
  };

  const onSearchBusiness = async (value: string) => {
    clearListForm();
    if (enteredValue || value?.trim()) {
      setEnteredValue(value?.trim());
      await getBusinessList({
        setLoading: setSearchLoading,
        searchKey: selectedSearchType,
        searchValue: value?.trim(),
        setData: setBusinessList
      });
    }
  };

  const onSearchBusinessChange = () => {
    setEnteredValue(initialState.enteredValue);
    clearListForm();
  };
  const onClickProceed = ({ selectedBusiness }: { selectedBusiness: string }) => {
    if (selectedBusiness) {
      setBusinessId(selectedBusiness);
    } else {
      setBusinessId('');
    }
  };

  const onChangeCompanySelect = () => {
    setBusinessId('');
    setPageLimit(20);
    setCurrentPage(1);
  };

  return (
    <div className="w-full max-w-[700px]">
      <Spin spinning={searchLoading}>
        <div className={`px-3 py-4 border border-gray-300 rounded`}>
          <div className="flex gap-2 flex-wrap items-center mb-3">
            <Formlabel labelText="Search Business by :" required />
            <Radio.Group defaultValue={defaultSearchType} onChange={onChangeSearchType}>
              <Radio value="brandName">Brand Name</Radio>
              <Radio value="gstin">GSTIN</Radio>
            </Radio.Group>
          </div>

          <Form
            layout="vertical"
            id="searchBusinessForm"
            form={searchBusinessForm}
            scrollToFirstError>
            <div className="flex flex-col gap-2">
              <Formlabel
                required
                labelText={`Enter ${selectedSearchType === 'brandName' ? 'Brand Name' : 'GSTIN'}`}
              />
              <Form.Item
                name="searchInput"
                rules={[
                  {
                    required: true,
                    message: `Please enter ${
                      selectedSearchType === 'brandName' ? 'brand name' : 'GSTIN'
                    } to proceed further`
                  }
                ]}>
                <Input.Search
                  allowClear
                  placeholder="Input search text"
                  onChange={onSearchBusinessChange}
                  onSearch={onSearchBusiness}
                  enterButton
                />
              </Form.Item>
            </div>
          </Form>

          {!searchLoading &&
            enteredValue &&
            (businessList?.length ? (
              <Form
                disabled={!enteredValue}
                layout="vertical"
                id="businessListForm"
                form={businessListForm}
                onFinish={onClickProceed}>
                <div className="flex flex-col gap-2">
                  <Formlabel required labelText="Select Business" />
                  <Form.Item
                    name="selectedBusiness"
                    rules={[
                      {
                        required: true,
                        message: `Please select any business`
                      }
                    ]}>
                    <Select
                      onChange={onChangeCompanySelect}
                      showSearch
                      allowClear
                      optionFilterProp="label"
                      placeholder="Select business"
                      options={businessList?.map((item) => ({
                        label: `${item?.id} - ${item?.brandName}`,
                        value: item?.id
                      }))}
                    />
                  </Form.Item>
                </div>

                <div className="flex justify-center">
                  <Button type="primary" htmlType="submit">
                    Fetch addresses
                  </Button>
                </div>
              </Form>
            ) : (
              <Empty
                className="py-4"
                description={
                  <p>
                    No business found for <span className="font-semibold">{enteredValue}</span>.
                  </p>
                }
              />
            ))}
        </div>
      </Spin>
    </div>
  );
};

export default BusinessDetails;
